//import { hidden } from "ansi-colors";

export const colors = {
  black: "#000000",
  white: "#ffffff",
  red: "#CE1526",
  gray: "#6A6C6E"
};

const fontSize = "0.875rem";
const fontWeight = 600;
const lineHeight = 1.5;
const selectHeight = "3rem";

export const customStyles = {
  container: (provided, state) => ({
    ...provided
  }),
  control: (provided, { menuIsOpen }) => ({
    ...provided,
    borderRadius: 0,
    borderColor: colors.black,
    boxShadow: 0,
    height: selectHeight,
    minHeight: selectHeight,
    whiteSpace: "nowrap",
    backgroundColor: menuIsOpen ? colors.black + " !important" : colors.black,
    fontWeight: fontWeight,
    fontFamily: "graphik",
    fontSize: fontSize,
    lineHeight: 2.2,
    alignItems: "center",

    "&:hover": {
      borderColor: colors.black
    },

    "&:active": {
      outline: 0
    },
    "&:focus": {
      outline: 0
    }
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: selectHeight
  }),
  input: (provided, state) => ({
    ...provided
    //color: state.isSelected ? colors.white : colors.red
  }),
  menuList: provided => ({
    ...provided,
    minHeight: "fit-content"
  }),
  placeholder: (provided, state) => ({
    color: state.menuIsOpen ? colors.white : colors.red,
    // whiteSpace: "nowrap",
    // flexWrap: "nowrap",
    // alignSelf: "flex-start",
    lineHeight: 3
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: colors.black,
    color: colors.white,
    fontWeight: fontWeight,
    fontSize: fontSize,
    lineHeight: lineHeight,
    fontFamily: "graphik",
    textAlign: "left",
    borderRadius: 0,
    marginTop: 0
  }),

  option: (
    provided,
    { data, isDisabled, isSelected, isFocused, isActive }
  ) => ({
    ...provided,
    opacity: isDisabled ? (isSelected ? 1 : 0.5) : 1,
    color: isFocused ? colors.white : isSelected ? colors.red : colors.white,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? null
      : isFocused
      ? colors.red
      : isActive
      ? "purple"
      : null,
    ":active": {
      ...provided[":active"],
      backgroundColor: !isDisabled && (isSelected ? colors.red : null)
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    transition: "opacity 300ms",
    color: colors.white,
    //backgroundColor: state.isSelected ? colors.white : colors.black,
    fontFamily: "graphik"
  })
};

export default customStyles;
