import React, { Component } from "react";
import Column from "./column";

class Columns extends Component {
  state = {};
  render() {
    const { compareSelected, selectedFilter, columnData } = this.props;
    return compareSelected.map((c, i) => (
      <Column
        key={"column-" + i}
        id={i}
        columnData={
          compareSelected[i] !== null
            ? columnData.filter(
                c => c.compareCategory === compareSelected[i].value
              )
            : ""
        }
        selectedFilter={selectedFilter ? selectedFilter.value : ""}
        compareSelected={compareSelected[i] ? compareSelected[i].value : ""}
      />
    ));
  }
}
export default Columns;
