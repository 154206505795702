import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "./selectStyles";

const Acc = styled.div`
  overflow: hidden;
  /* &:last-child {
    border-bottom: 1px solid ${colors.red};
  } */
  & + & .expander {
      border-top: 0;
  }
  & .expander {
    border-top: 1px solid ${colors.red};
    border-bottom: 1px solid ${colors.red};
    padding: 0.625rem 0;
    text-align: left;
    padding-right: 1rem;
    position: relative;
    text-align: left;
    /* margin: 1rem 0; */
    
  }
  & .expander::after {
    content: "+";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  & .expandable {
    border-bottom: 1px solid ${colors.red};
    padding: 1rem 0;
    color: ${colors.red};
    a,
    a:visited,
    a:focus {
      font-weight: 600;
      color: ${colors.red};
    }
  }
  li + li {
    margin-top: 0.5rem;
  }
`;

class Accordion extends Component {
  state = {
    show: false
  };

  accordionToggle = selected => {
    console.log("clickes");
    this.setState({ show: !this.state.show });
  };
  render() {
    return (
      <Acc>
        <div className="expander" onClick={this.accordionToggle}>
          {this.props.parentTitle}
        </div>
        {this.state.show ? (
          <div
            className="expandable"
            dangerouslySetInnerHTML={{
              __html: this.props.expandedDetail
            }}
          />
        ) : null}
      </Acc>
    );
  }
}

export default Accordion;
