import React, { Component } from "react";
import Select from "react-select";
import customStyles, { colors } from "./selectStyles";
import styled from "styled-components";

const DataValue = styled.div`
  width: 100%;
  .react-select > div[class$="-control"] {
    ${({ initialized }) => !initialized && `background-color: #fff;`}
  }
`;

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  textTransform: "uppercase",
  fontSize: "0.75rem",
  fontFamily: "graphik",
  fontWeight: 600,
  color: colors.gray
};
const formatGroupLabel = data => (
  <div className="select-group" style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
);

class ColumnSelect extends Component {
  state = {};
  render() {
    //    console.log(this.props.selectedValue);
    return (
      <DataValue initialized={this.props.initialized}>
        <Select
          noOptionsMessage={() => "loading..."}
          placeholder={this.props.placeholder}
          options={this.props.options}
          value={this.props.selectedValue}
          onChange={e => this.props.onChange(e, this.props.index)}
          styles={customStyles}
          className="react-select"
          isSearchable={false}
          formatGroupLabel={formatGroupLabel}
        />
      </DataValue>
    );
  }
}

export default ColumnSelect;
