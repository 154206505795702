import React, { Component } from "react";
import ColumnSelect from "./columnselect";
import Columns from "./columns";
import FilterComponent from "./filtercomponent";
import "./datatable.scss";
import styled from "styled-components";

const FilterCell = styled.div`
  width: 25%;
  border-left: 1px solid #000;
  text-transform: uppercase;
  font-family: "trade-gothic-next-compressed";
  font-weight: 700;
  min-height: 200px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  box-sizing: border-box;
  font-size: 1.25rem;
  text-align: left;
  &:last-child {
    border-right: 1px solid #000;
  }
  .row:last-child & {
    border-bottom: 1px solid #000;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    width: 33%;
    min-width: 33%;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    min-width: 50%;
  }
`;
const HeaderCell = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 0.5rem;
  border-left: 1px solid #000;
  display: ${props => (props.initialized ? "block" : "flex")};
  align-items: flex-start;
  @media (max-width: 768px) {
    width: 33%;
    min-width: 33%;
    &:nth-child(3) {
      border-right: 1px solid #000;
    }
    &:nth-child(n + 4) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    min-width: 50%;
    font-size: 1rem;
    &:nth-child(2) {
      border-right: 1px solid #000;
    }
    &:nth-child(n + 3) {
      display: none;
    }
  }
  &:last-child {
    border-right: 1px solid #000;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  &:nth-child(2n + 1) {
    background-color: rgba(216, 216, 216, 0.5);
  }
  &:last-child {
    border-bottom: 1px solid #000;
  }
  &:first-child {
    background-color: transparent;
    border: none;
  }
`;

class DataTable extends Component {
  render() {
    const {
      compOptions,
      filterOptions,
      selectedOption,
      compareSelected,
      onCompareChange,
      onFilterChange,
      comparePlaceholder,
      filterPlaceholder,
      initialized
    } = this.props;
    return (
      <section>
        <Row className={initialized[0] ? "row--head" : "row--head init"}>
          <HeaderCell>
            <FilterComponent
              options={filterOptions}
              selectedOption={selectedOption}
              onChange={onFilterChange}
              initialized={initialized[0]}
              placeholder={filterPlaceholder}
            />
          </HeaderCell>
          {compareSelected.map((c, i) => (
            <HeaderCell
              initialized={initialized[i + 1]}
              key={"header-cell-" + i}
            >
              <ColumnSelect
                key={"c-select-" + i}
                onChange={onCompareChange}
                options={compOptions}
                selectedValue={c}
                placeholder={comparePlaceholder}
                initialized={initialized[i + 1]}
                index={i}
              />
            </HeaderCell>
          ))}
        </Row>

        {this.props.compData
          .filter(c => selectedOption && c.parent === selectedOption.value)
          .map((c, i) => (
            <Row key={"row-" + i}>
              <FilterCell>{c.title}</FilterCell>
              <Columns
                columnData={c.contentBlocks}
                selectedFilter={selectedOption}
                compareSelected={compareSelected}
              />
            </Row>
          ))}
      </section>
    );
  }
}

export default DataTable;
