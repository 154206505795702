import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components";
import customStyles, { colors } from "./selectStyles";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  textTransform: "uppercase",
  fontSize: "0.625rem",
  fontFamily: "graphik",
  fontWeight: 600,
  color: colors.gray
};
// const groupBadgeStyles = {
//   backgroundColor: "#EBECF0",
//   borderRadius: "2em",
//   color: "blue",
//   display: "inline-block",
//   fontSize: 12,
//   fontWeight: "bold",
//   lineHeight: "1",
//   minWidth: 1,
//   padding: "0.16666666666667em 0.5em",
//   textAlign: "center"
// };

const ColumnHeadCell = styled.div`
  font-family: "trade-gothic-next-compressed";
  font-weight: 700;
  padding-left: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  border-bottom: 2px solid black;
  display: block;
  padding-bottom: 7px;
  text-align: left;
`;
const DataValue = styled.div`
  text-transform: uppercase;
  width: 100%;
  .react-select > div[class$="-control"] {
    ${({ initialized }) => !initialized && `background-color: #fff;`}
  }
  .react-select:active,
  .react-select:hover {
    > div[class$="-control"] {
      background: #000;
    }
  }
`;

const formatGroupLabel = data => (
  <div className="select-group" style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>{data.options.length}</span> */}
  </div>
);

class FilterComponent extends Component {
  render() {
    const {
      options,
      selectedOption,
      onChange,
      initialized,
      placeholder
    } = this.props;

    return (
      <DataValue initialized={initialized}>
        {options.length > 1 ? (
          <Select
            noOptionsMessage={() => "loading..."}
            className="react-select"
            placeholder={placeholder}
            value={selectedOption}
            onChange={onChange}
            options={options}
            styles={customStyles}
            formatGroupLabel={formatGroupLabel}
          />
        ) : options && options[0] !== undefined ? (
          <ColumnHeadCell>{options[0].label}</ColumnHeadCell>
        ) : (
          ""
        )}
      </DataValue>
    );
  }
}

export default FilterComponent;
