import React, { Component } from "react";
import styled from "styled-components";
import Accordion from "./accordion";

const Cell = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 5px;
  border-left: 1px solid #000;
  padding: 2rem;
  box-sizing: border-box;
  font-size: 0.75rem;
  text-align: left;
  line-height: 1.2;
  p,
  ul,
  ol {
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    font-family: "graphik";
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
  }
  ul {
    padding-left: 1rem;
  }
  &:last-child {
    border-right: 1px solid #000;
  }
  .row:last-child & {
    border-bottom: 1px solid #000;
  }
  .cell__content p {
    ${({ hasContext }) => hasContext && `font-weight: 700; text-align: center`}
  }
  .cell__content ul {
    line-height: 1.8;
  }
  @media (max-width: 1024px) {
    font-size: 1.1rem;
  }
  @media (max-width: 768px) {
    width: 33%;
    min-width: 33%;
    &:nth-child(3) {
      border-right: 1px solid #000;
    }
    &:nth-child(n + 4) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    width: 50%;
    min-width: 50%;
    &:nth-child(2) {
      border-right: 1px solid #000;
    }
    &:nth-child(n + 3) {
      display: none;
    }
  }
`;

class Column extends Component {
  state = {};
  render() {
    const { columnData } = this.props;
    const contextRows =
      columnData[0] !== undefined && columnData[0].contextRows !== null
        ? columnData[0].contextRows
        : [];
    return (
      <Cell hasContext={contextRows.length}>
        <div
          className="cell__content"
          dangerouslySetInnerHTML={{
            __html: columnData[0] !== undefined ? columnData[0].data : ""
          }}
        />
        {contextRows.map((c, i) =>
          c.data ? (
            <Accordion
              parentTitle={c.title}
              expandedDetail={c.data}
              key={"acc_" + i}
            />
          ) : (
            <p>
              <strong>{c.title}</strong>
            </p>
          )
        )}
      </Cell>
    );
  }
}

export default Column;
