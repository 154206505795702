import React, { Component } from "react";
//import ThemeProvider from "styled-components";
import axios from "axios";
import DataTable from "./datatable";

import "../App.scss";

/* Style Component - Move to individual files */
import styled from "styled-components";
const Title = styled.h1`
  color: black;
  font-size: 3.6rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 1024px) {
    font-size: 3rem;
  }
`;
const Explainer = styled.div`
  p {
    font-weight: 500;
    line-height: 1.5;
    font-size: 1.25rem;
    margin-bottom: 3rem;
  }
`;

/* App */
const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://knight.craft3.local/v1/"
    : "https://charts.knightcolumbia.org/v1/";

class Chart extends Component {
  state = {
    chart: "",
    heading: "",
    explainer: "",
    options: [],
    compOptions: [],
    data: [],
    selectedOption: "",
    compareSelected: [],
    initialized: [false, false, false, false],
    comparePlaceholder: "",
    filterPlaceholder: ""
  };

  componentDidMount() {
    //AJAX data from server
    //this.setState({ movies })
    console.log("App - mounted");
    var _this = this;
    var compareCategory = "";
    var dataField = "";
    var dataSection = "";

    this.serverRequest = axios
      .get(apiUrl + "viz/" + this.props.chartToLoad + ".json")
      .then(function(settings) {
        console.log(settings.data.data);
        settings = settings.data.data[0];

        compareCategory = settings.compareCategory;
        dataField = settings.dataField;
        dataSection = settings.dataSection;

        _this.setState({
          heading: settings.chartTitle,
          explainer: settings.explainer,
          comparePlaceholder: settings.comparePlaceholder,
          filterPlaceholder: settings.filterPlaceholder
        });
        //other requests
        _this.serverRequest = axios
          .get(apiUrl + "categories/" + compareCategory + ".json")
          .then(function(result) {
            //console.log("categories", result.data.data);
            _this.setState({
              compOptions: result.data.data,
              compareSelected: [null, null, null]
            });
          });

        _this.serverRequest = axios
          .get(apiUrl + "filters/" + dataSection + ".json")
          .then(function(result) {
            console.log("table-data", result.data.data);
            //selectedOption: selectedOption,
            const filters = result.data.data;
            var initialized = [..._this.state.initialized];
            initialized[0] = true;
            if (filters.length === 1) {
              _this.setState({
                options: filters,
                selectedOption: filters[0],
                initialized: initialized
              });
            } else {
              _this.setState({
                options: _this.groupOptions(filters)
                // selectedOption: result.data.data[0]
              });
            }
          });

        _this.serverRequest = axios
          .get(
            apiUrl + "table-data/" + dataSection + ".json?column=" + dataField
          )
          .then(function(result) {
            _this.setState({
              data: result.data.data
            });
          });
      });
  }
  handleFilterChange = selectedOption => {
    //console.log("Filter Changed", selectedOption);
    let initialized = [...this.state.initialized];
    initialized[0] = true;
    this.setState({ selectedOption: selectedOption, initialized: initialized });
    //console.log(`Option selected:`, selectedOption);
  };
  handleCompareChange = (selectedOption, compareIndex) => {
    //console.log("col changed", selectedOption, compareIndex);
    let compareSelected = [...this.state.compareSelected];
    let initialized = [...this.state.initialized];

    compareSelected[compareIndex] = selectedOption;
    initialized[compareIndex + 1] = true;

    let compOptions = [...this.state.compOptions];
    compOptions.map(opt =>
      compareSelected.indexOf(opt) > -1
        ? (opt.isDisabled = true)
        : (opt.isDisabled = false)
    );

    this.setState({ compareSelected, initialized: initialized });
    //this.setState({ options });
    //this.setState({});
  };
  groupOptions = options => {
    let groupedOptions = [];
    let currCat = null;
    let groupCount = 1;

    // STUB: api should be re-written to retrieve
    groupedOptions.push({ label: "", options: [] });

    for (let i = 0; i < options.length; i++) {
      let cat = options[i]["category"];

      console.log("cat", cat, "currCat", currCat);
      if (cat) {
        if (currCat !== cat) {
          groupedOptions.push({ label: cat, options: [] });
          groupCount = 1;
          //groupedOptions[groupedOptions.length - 1]["options"].push(options[i]);
          currCat = cat;
        }
        //console.log(options[i]);
        options[i]["label"] = groupCount + ". " + options[i]["label"];
        groupedOptions[groupedOptions.length - 1]["options"].push(options[i]);
        groupCount++;
      } else {
        console.log("no cat");
        //options[i]["label"] = options[i]["label"];
        groupedOptions.push(options[i]);
      }
      //}
    }
    console.log(groupedOptions);

    return groupedOptions;
  };

  render() {
    const {
      heading,
      options,
      compOptions,
      data,
      selectedOption,
      compareSelected,
      initialized,
      explainer,
      comparePlaceholder,
      filterPlaceholder
    } = this.state;
    return (
      <div className="App">
        <header>
          <Title>{heading}</Title>
          <Explainer dangerouslySetInnerHTML={{ __html: explainer }} />
        </header>
        {/* <FilterComponent
                options={options}
                selectedOption={selectedOption}
                onChange={this.handleFilterChange}
              /> */}
        <DataTable
          filterOptions={options}
          compOptions={compOptions}
          compData={data}
          selectedOption={selectedOption}
          compareSelected={compareSelected}
          comparePlaceholder={comparePlaceholder}
          filterPlaceholder={filterPlaceholder}
          onCompareChange={this.handleCompareChange}
          onFilterChange={this.handleFilterChange}
          initialized={initialized}
        />
      </div>
    );
  }
}

export default Chart;
