import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Chart from "./components/chart";

class App extends Component {
  // state = {
  //   chart: "ppr-regulations"
  // };

  render() {
    // const { chart } = this.state;
    return (
      <Router>
        <Route exact path="/type/:viz" component={ComponentToLoad} />
      </Router>
    );
  }
}
function ComponentToLoad({ match }) {
  return (
    <main>
      <Chart chartToLoad={match.params.viz} />
    </main>
  );
}

export default App;
